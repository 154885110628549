const config = require('../config/' + process.env.NODE_ENV)

//import axios from "axios"
//import { i18n } from 'vue-lang-router';
// import store from '../store'

let core = {
    baseUrl: function(){
        return config.root_node.url + ":" + config.root_node.port + "/"
    },
    getStrDate(d){
      if(d == null) return "null"
      let date = new Date(d)
      let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate()
      let month = date.getMonth() < 9 ? "0" + (date.getMonth()+1) : (date.getMonth()+1)
      return day + "/" + month + "/" + date.getFullYear()
    },
    nl2br: function(str, is_xhtml){
        var breakTag = (is_xhtml || typeof is_xhtml === 'undefined') ? '<br ' + '/>' : '<br>'
        let newStr = (str + '').replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1' + breakTag + '$2')
        return newStr
    },

}

export default core